import React from 'react'

function FirstTrackPrivacyPolicy() {
  return (
    <div>
        <h1>First Track Privacy Policy</h1>
        <p>First track does not collect any data on the user. The data shown on the phone is stored on the phone running the app.</p>
    </div>
  )
}

export default FirstTrackPrivacyPolicy