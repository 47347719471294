import dayjs from "dayjs";

const mockData = {
    "screentime-beneficial-content": 90,
    "family-relationships": "2",
    "family-support": "3",
    "family-time": "3",
    "health-rate": "4",
    "health-stress": "4",
    "health-sleep": "4",
    "social-support": "4",
    "social-life": "4",
    "social-comfort": "2",
    "growth-committment": "4",
    "growth-comfortzone": "8",
    "growth-progress": "3",
    "intellect-stimulation": "4",
    "intellect-challenge-frequency": "3",
    "intellect-expertise": "2",
    "spirituality-connection": "5",
    "spirituality-fulfillment": "2",
    "career-satisfaction": "2",
    "career-alignemnt": "4",
    "career-fulfillment": "5",
    "relationships-quality": "1",
    "relationships-satisfaction": "1",
    "relationships-communication": "1",
    "environment-satisfaction": "4",
    "environment-safety": "5",
    "environment-contribution": "4",
    "vision-clarity": "3",
    "vision-alignment": "4",
    "vision-optimism": "4",
    "hobbies-time-available": dayjs("2022-04-17T22:10:00"),
    "hobbies-time-dedicated": dayjs("2022-04-17T01:10:00"),
    "hobbies-fulfillment": "4",
    "hobbies-variety": "4",
    "screentime-satisfaction": "4",
    "screentime-distraction-frequency": "1",
    "email": "trevor_h_marshall@icloud.com",
    "spirituality-practices-frequency": "3"
};

const handleSubmit = (event, form) => {
    event.preventDefault();

    // Convert string values to numbers or dates
    const parsedForm = Object.keys(form).reduce((acc, key) => {
        if (typeof form[key] === 'string') {
            if (!isNaN(form[key])) {
                acc[key] = parseFloat(form[key]);
            } else if (dayjs(form[key]).isValid()) {
                acc[key] = dayjs(form[key]);
            } else {
                acc[key] = form[key];
            }
        } else {
            acc[key] = form[key];
        }
        return acc;
    }, {});

    const mapScreentimeBeneficialContent = (percentage) => {
        if (percentage <= 20) return 1;
        if (percentage <= 50) return 3;
        if (percentage <= 80) return 4;
        if (percentage <= 100) return 5;
        return 0;
    };

    // Apply the mapping
    parsedForm['screentime-beneficial-content'] = mapScreentimeBeneficialContent(parsedForm['screentime-beneficial-content']);

    // Calculate percentage of time available out of time dedicated for hobbies
    const calculateTimePercentage = (available, dedicated) => {
        const availableMinutes = available.hour() * 60 + available.minute();
        const dedicatedMinutes = dedicated.hour() * 60 + dedicated.minute();
        if (dedicatedMinutes === 0) return 0; // Avoid division by zero
        return (availableMinutes / dedicatedMinutes) * 100;
    };

    const mapTimePercentage = (percentage) => {
        if (percentage <= 20) return 1;
        if (percentage <= 30) return 2;
        if (percentage <= 60) return 3;
        if (percentage <= 80) return 4;
        if (percentage <= 100) return 5;
        return 0;
    };

    const availableTime = parsedForm['hobbies-time-available'];
    const dedicatedTime = parsedForm['hobbies-time-dedicated'];
    const timePercentage = calculateTimePercentage(availableTime, dedicatedTime);
    parsedForm['hobbies-time-available'] = mapTimePercentage(timePercentage);

    // Group fields by sections
    const sections = {
        screentime: ['screentime-beneficial-content', 'screentime-satisfaction', 'screentime-distraction-frequency'],
        family: ['family-relationships', 'family-support', 'family-time'],
        health: ['health-rate', 'health-stress', 'health-sleep'],
        social: ['social-support', 'social-life', 'social-comfort'],
        growth: ['growth-committment', 'growth-comfortzone', 'growth-progress'],
        intellect: ['intellect-stimulation', 'intellect-challenge-frequency', 'intellect-expertise'],
        spirituality: ['spirituality-connection', 'spirituality-fulfillment', 'spirituality-practices-frequency'],
        career: ['career-satisfaction', 'career-alignemnt', 'career-fulfillment'],
        relationships: ['relationships-quality', 'relationships-satisfaction', 'relationships-communication'],
        environment: ['environment-satisfaction', 'environment-safety', 'environment-contribution'],
        vision: ['vision-clarity', 'vision-alignment', 'vision-optimism'],
        hobbies: ['hobbies-time-available', 'hobbies-fulfillment', 'hobbies-variety'], // 'hobbies-time-dedicated' is not included because it is used in calculations above.
    };

    const sectionRatings = {};

    // Calculate the average rating for each section
    for (const section in sections) {
        const sectionFields = sections[section];
        const total = sectionFields.reduce((sum, field) => sum + parsedForm[field], 0);
        const average = total / sectionFields.length;
        sectionRatings[section] = average * 2;
    }

    // Calculate the overall life score
    const totalSectionRatings = Object.values(sectionRatings).reduce((sum, rating) => sum + rating, 0);
    const overallLifeScore = totalSectionRatings / Object.keys(sectionRatings).length;

    console.log('Section Ratings:', sectionRatings);
    console.log('Overall Life Score:', overallLifeScore);

    // Add overall life score to the form data
    parsedForm['overall-score'] = overallLifeScore.toFixed(1);
    delete parsedForm['hobbies-time-dedicated'];

    // Send data to the Lambda endpoint
    fetch('https://dodfhzjtepucnkuxgor6gbx65i0fmhwz.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(parsedForm)
    })
    .then(response => response.json())
    .then(data => console.log('Success:', data))
    .catch(error => console.error('Error:', error));

    return { sectionRatings, overallLifeScore };
};

export { handleSubmit, mockData };
