import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import TuffyBoldItalic from './fonts/Tuffy_Bold_Italic-webfont.woff';
import TuffyItalic from './fonts/Tuffy_Italic-webfont.woff';
import TuffyBold from './fonts/Tuffy_Bold-webfont.woff';
import Tuffy from './fonts/Tuffy-webfont.woff';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const theme = createTheme({
  palette: {
    mode: 'light',
    typography: {
      fontFamily: 'Tuffy, TuffyBoldItalic, TuffyBold, Roboto',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Tuffy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Tuffy'), url(${Tuffy}) format('woff');
        }

        @font-face {
          font-family: 'Tuffy';
          font-style: italic;
          font-display: swap;
          font-weight: 400;
          src: local('TuffyBoldItalic'), url(${TuffyBoldItalic}) format('woff');
        }

        @font-face {
          font-family: 'Tuffy';
          font-style: bold;
          font-display: swap;
          font-weight: 400;
          src: local('TuffyBold'), url(${TuffyBold}) format('woff');
        }

        @font-face {
          font-family: 'Tuffy';
          font-style: bold;
          font-display: swap;
          font-weight: 400;
          src: local('TuffyBold'), url(${TuffyItalic}) format('woff');
        }
        `,
      },
    },
    background: {
      default: '#F8FEFF',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <App />
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
