import React from 'react'
import { Box, Typography } from '@mui/material'

export default function Footer() {
  return (
    <Box sx={{ mt: 5, textAlign: 'center', py: 3, backgroundColor: '#f0f0f0' }}>
        <Typography variant='body2' sx={{ fontFamily: "Tuffy" }}>2024 • First Life</Typography>
    </Box>
  )
}
