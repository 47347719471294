import './App.css';
import EvaluationForm from './components/EvaluationForm';
import LifeReport from './components/LifeReport';
import FirstTrackPrivacyPolicy from './components/FirstTrackPrivacyPolicy';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<EvaluationForm />} />
          <Route path="/life-report" element={<LifeReport />} />
          <Route path="/firsttrack-privacy-policy" element={<FirstTrackPrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
