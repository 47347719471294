import React from 'react';
import { useLocation } from 'react-router-dom';
import LeftSideOrnaments from '../assets/images/reportLeftSideOrnaments.png';
import RightSideOrnaments from '../assets/images/reportRightSideOrnaments.png';
import { Box, Typography, Divider, ListItem, Alert, List, ListItemText } from '@mui/material';
import Footer from './Footer';

const LifeReport = () => {
  const location = useLocation();
  const { sectionRatings, overallLifeScore } = location.state || {};

  const evaluateRating = (rating) => {
    if (rating >= 8) {
      return ['Amazing!', 'Well done! Time to make your world happen.'];
    } else if (rating >= 6) {
      return ['Great!', 'You\'re well on your way to your best self.'];
    } else if (rating >= 4) {
      return ['Good', 'It looks like it\'s your turn to make a move.'];
    } else if (rating >= 2) {
      return ['Poor', 'It looks like it\'s your turn to make a move.'];
    } else {
      return ['Very Poor', 'This report is your key to success.'];
    }
  };

  const sortedSectionRatings = Object.entries(sectionRatings).sort((a, b) => b[1] - a[1]);

  return (
    <Box sx={{ marginTop: "60px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 5, pl: 3, pr: 1 }}>
        <img src={RightSideOrnaments} alt="Right side of the page fine-line decoration" style={{ position: 'absolute', top: '-39px', right: '0px' }} />
        <Typography sx={{ mb: 1, fontFamily: "Tuffy", ml: 2, position: "relative" }} variant="h4"><strong><em>Your</em></strong> Report
          <img src={LeftSideOrnaments} alt="Left side of the page fine-line decoration" style={{ position: 'absolute', bottom: '-125px', left: '-40px' }} />
        </Typography>

        <Box sx={{ px: 2, mt: 1, mb: 5 }}>
          <Typography variant="body1" sx={{ fontFamily: "Tuffy" }}>Please use this report to <strong>guide you</strong>, not rule your life. Ignore what you want, and <u>focus on what you value.</u></Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 5, px: 3, position: "relative" }}>
        <Box sx={{ textAlign: "center", mb: 5 }}>
          <Typography variant='h4' sx={{ fontFamily: "Tuffy" }}>Overall Score:</Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', width: 'min-content', mx: 'auto', my: 5, position: 'relative'}}>
            <Typography variant='h4' sx={{ fontSize: "50px", fontFamily: "Tuffy"}}>{overallLifeScore.toFixed(1)}</Typography>
            <Typography variant='caption' sx={{fontFamily: "Tuffy", position: 'absolute', right: '-60px', bottom: '8px'}}>Out of 10</Typography>
          </Box>
          <Typography variant='h4' sx={{ fontFamily: "Tuffy", fontSize: "33px", color: "#5FAF43", mb: 5 }}>{evaluateRating(overallLifeScore)[0]}</Typography>
        </Box>

        <Box sx={{ px: 2, mt: 2, mb: 5, textAlign: 'center' }}>
          <Typography variant="body1" sx={{ fontFamily: "Tuffy" }}>{evaluateRating(overallLifeScore)[1]}</Typography>
          <Typography variant="body1" sx={{ fontFamily: "Tuffy" }}><strong>Find out which categories you'd like to improve based on your numbers below!</strong></Typography>
        </Box>

        <Box sx={{ mb: 5 }}>
          <Typography variant='h6' sx={{ fontFamily: "Tuffy", mb: 2 }}>Category Scores <Typography variant='caption'>(Highest to Lowest)</Typography></Typography>
          {sortedSectionRatings.map(([section, rating]) => (
            <Box sx={{ fontFamily: "Tuffy" }} key={section}>
              <Divider fullWidth />
              <ListItem style={{ color: rating < 4 ? '#FF4F4F' : 'inherit', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ textTransform: "capitalize" }}><strong>{section}</strong></div>
                <div>{rating.toFixed(1)}</div>
              </ListItem>
            </Box>
          ))}
        </Box>

        <Divider variant="middle" fullWidth sx={{ mt: 5 }} />

        <Box sx={{ mt: 5 }}>
          <Typography variant='h4' sx={{ fontFamily: "Tuffy", textAlign: "center", mb: 2 }}><u>How To Improve:</u></Typography>
          <Alert sx={{ marginBottom: 2, fontFamily: "Tuffy" }} severity='info'>If you aren't in touch with <strong>who you are as a person</strong>, these steps may be hard at first. But with <em>each reflection</em> you'll strengthen this <u>'introspection' muscle</u> and life will slowly, but surely, fall into place.</Alert>
          <Typography variant='h6' sx={{ fontFamily: "Tuffy", mb: 2 }}>Steps: <Typography variant='caption'>(Applicable to every section)</Typography></Typography>

          <List sx={{ listStyleType: 'decimal', pl: 4, fontFamily: "Tuffy" }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary="Get a pencil & paper or open up a note" />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary="Reflect and start writing about your problem sections (e.g. traumatic events, issues, feelings, events, ideas, preconceptions, etc)" />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary="For every point, address it with a solution and combat those issues" />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary="For every solution, create a rough plan of action that would resolve those issues" />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary="For every plan of action, create a recurring reminder/task that gets you closer to your goal passively (start small)" />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primary="Reflect on the most important sections weekly (set a reminder and repeat the above steps to find what works for you)" />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Typography variant='h5' sx={{ fontFamily: "Tuffy", mb: 2 }}>Stay Tuned for Our App - <em>First Life!</em></Typography>
          <Typography variant='body1' sx={{ fontFamily: "Tuffy", mb: 2 }}>We are excited to announce the upcoming launch of our app, <strong>First Life</strong>, designed to help you on your journey towards self-improvement. Stay tuned for more updates!</Typography>
        </Box>

      </Box>
        <Footer />
    </Box>
  );
};

export default LifeReport;
