import {
  labels,
  labelsSatisfied,
  labelsComfort,
} from "./RatingLabels";

import { frequencyOptions, frequencyOptionsNegative } from "./SelectOptions";

let formConfig = [
  {
    name: "Family",
    questions: [
      {
        label: "Rate the quality of your relationships with your family members.",
        id: "family-relationships",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "Rate how supported you feel by your family.",
        id: "family-support",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "How satisfied are you with the amount of time you spend with your family?",
        id: "family-time",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      }
    ]
  },
  {
    name: "Health",
    questions: [
      {
        label: "Rate your overall physical health",
        id: "health-rate",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "Rate your ability to manage your stress and mental health.",
        id: "health-stress",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "How satisfied are you with your sleep quality and quantity?",
        id: "health-sleep",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      }
    ]
  },
  {
    name: "Social",
    questions: [
      {
        label: "Rate the strength of your social support network",
        id: "social-support",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "How satisfied are you with your social life and interactions?",
        id: "social-life",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      },
      {
        label: "How comfortable are you in social situations?",
        id: "social-comfort",
        type: "number",
        step: 1,
        labels: labelsComfort
      }
    ]
  },
  {
    name: "Growth/Personal Development",
    questions: [
      {
        label: "Rate your commitment to learning and personal growth?",
        id: "growth-committment",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "How often do you step out of your comfort zone to try new things?",
        id: "growth-comfortzone",
        type: "select",
        optionLabel: "Select Frequency",
        options: frequencyOptions
      },
      {
        label: "How satisfied are you with your progress towards personal goals?",
        id: "growth-progress",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      }
    ]
  },
  {
    name: "Intellectually",
    questions: [
      {
        label: "How intellectually stimulated do you feel in your daily life?",
        id: "intellect-stimulation",
        type: "select",
        optionLabel: "Select Frequency",
        options: [
          { value: "", label: "Select Option" },
          { value: "5", label: "Very" },
          { value: "4", label: "Often" },
          { value: "2", label: "Little" },
          { value: "1", label: "Seldom" }
        ]
      },
      {
        label: "How often do you engage in activities that challenge your mind?",
        id: "intellect-challenge-frequency",
        type: "select",
        optionLabel: "Select Frequency",
        options: frequencyOptions
      },
      {
        label: "How satisfied are you with your level of knowledge and expertise in areas of interest?",
        id: "intellect-expertise",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      }
    ]
  },
  {
    name: "Spirituality",
    questions: [
      {
        label: "How satisfied do you feel in your sense of purpose and meaning in life?",
        id: "spirituality-connection",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      },
      {
        label: "How often do you engage in practices that nurture your spiritual well-being?",
        id: "spirituality-practices-frequency",
        type: "select",
        optionLabel: "Select Frequency",
        options: frequencyOptions
      },
      {
        label: "How satisfied are you with your level of spiritual fulfillment?",
        id: "spirituality-fulfillment",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      }
    ]
  },
  {
    name: "Career",
    questions: [
      {
        label: "How satisfied are you with your current job or career path?",
        id: "career-satisfaction",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      },
      {
        label: "Rate the alignment of your career with your values and interests.",
        id: "career-alignemnt",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "Rate how challenged and fulfilled you feel in your professional life.",
        id: "career-fulfillment",
        type: "number",
        step: 1,
        labels: labels
      }
    ]
  },
  {
    name: "Relationships",
    questions: [
      {
        label: "Rate the quality of your romantic relationships.",
        id: "relationships-quality",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "How satisfied are you with your friendships and close connections?",
        id: "relationships-satisfaction",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      },
      {
        label: "Rate how effectively you communicate and resolve conflicts in your relationships.",
        id: "relationships-communication",
        type: "number",
        step: 1,
        labels: labels
      }
    ]
  },
  {
    name: "Quality of Life & Environment",
    questions: [
      {
        label: "How satisfied are you with your living environment?",
        id: "environment-satisfaction",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      },
      {
        label: "Rate how safe and secure you feel in your surroundings.",
        id: "environment-safety",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "Rate how much your environment contributes to your overall well-being.",
        id: "environment-contribution",
        type: "number",
        step: 1,
        labels: labels
      }
    ]
  },
  {
    name: "Life Vision",
    questions: [
      {
        label: "Rate how clear your vision is for your future.",
        id: "vision-clarity",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "Rate how aligned your actions are your long-term goals and aspirations.",
        id: "vision-alignment",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "Rate how optimistic are you about your future prospects?",
        id: "vision-optimism",
        type: "number",
        step: 1,
        labels: labels
      }
    ]
  },
  {
    name: "Hobbies",
    questions: [
      {
        label: "How much time do you have available for things other than work or obligations everyday?",
        id: "hobbies-time-available",
        type: "duration",
      },
      {
        label: "How much time do you dedicate to activities you enjoy outside of work or obligations everyday?",
        id: "hobbies-time-dedicated",
        type: "duration",
      },
      {
        label: "Rate how fulfilling your hobbies and interests are.",
        id: "hobbies-fulfillment",
        type: "number",
        step: 1,
        labels: labels
      },
      {
        label: "How satisfied are you with the variety of hobbies you engage in?",
        id: "hobbies-variety",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      }
    ]
  },
  {
    name: "Screentime & Technology",
    questions: [
      {
        label: "How satisfied are you with the time you spend on your phone, laptop, consoles, or other devices?",
        id: "screentime-satisfaction",
        type: "number",
        step: 1,
        labels: labelsSatisfied
      },
      {
        label: "How much of that time is spent exploring beneficial content or necessary life sustaining activities?",
        id: "screentime-beneficial-content",
        type: "percent",
        min: 1,
        max: 100,
        placeholder: "%"
      },
      {
        label: "How often do you find yourself picking up your phone or navigating to a distraction for no good reason?",
        id: "screentime-distraction-frequency",
        type: "select",
        optionLabel: "Select Frequency",
        options: frequencyOptionsNegative
      }
    ]
  }
];

export default formConfig