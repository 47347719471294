import React from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

export default function Question({question, formValues, handleChange}) {

    const [hover, setFormHover] = React.useState({});


    const handleHover = (id, value) => {
        setFormHover({[id]: value });
      };

    return (
        <Box sx={{ mb: 3, mx: 2 }}>
            <Typography variant="subtitle">• {question.label}</Typography>
            {question.type === 'select' ? (
            <FormControl fullWidth sx={{mt: 2}}>
                <InputLabel id={`${question.id}-label`}>{question.optionLabel}</InputLabel>
                <Select
                id={question.id}
                value={formValues[question.id] || ''}
                onChange={(e) => handleChange(question.id, e.target.value)}
                labelId={`${question.id}-label`}
                label={question.label}
                required
                >
                {question.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            ) : question.type === 'duration' ? (
                <Box sx={{display: "flex", justifyContent: "center", py: 2}}>
                    <TimePicker
                        label="Hours & Minutes"
                        value={formValues[question.id] || dayjs('2022-04-17T02:10')}
                        onChange={(newValue) => handleChange(question.id, newValue)}
                        ampm={false}
                    />
                </Box>
            ) : question.type === 'percent' ? (
                <Box sx={{display: "flex"}}>
                    <Slider
                        aria-label="Screentime Beneficial Content"
                        defaultValue={50}
                        value={formValues[question.id] || 50}
                        onChange={(event, newValue) => handleChange(question.id, newValue)}
                        step={10}
                        marks
                        min={10}
                        max={100}
                        valueLabelFormat={(x) => {return x + "%"}}
                        valueLabelDisplay="auto"
                        required
                    />
                    <Typography sx={{ marginTop: 1, width: "60px", textAlign: "right" }}>{formValues[question.id]}%</Typography>
                </Box>
            ) : question.type === 'number' ? (
            <Box sx={{ textAlign: "center"}}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography variant='caption' sx={{ marginTop: 1, width: "min-content" }}>{question.labels[1]}</Typography>
                    <Rating
                    id={question.id}
                    value={formValues[question.id] || 0}
                    precision={question.step}
                    onChange={(event, newValue) => handleChange(question.id, newValue)}
                    onChangeActive={(event, newValue) => handleHover(question.id, newValue)}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                    size="large"
                    required
                    />
                <Typography variant='caption' sx={{ marginTop: 1, width: "min-content" }}>{question.labels[5]}</Typography>
                </Box>
                {hover[question.id] !== -1 && (
                <Typography sx={{ marginTop: 1 }}>{question.labels[hover[question.id]]}</Typography>
                )}
            </Box>
            ) : (
            <input
                id={question.id}
                type={question.type}
                className="form-control"
                value={formValues[question.id] || ''}
                onChange={(e) => handleChange(question.id, e.target.value)}
                min={question.min}
                max={question.max}
                required
            />
            )}
        </Box>
    )
}
