const frequencyOptions = [
    { value: "5", label: "Everyday" },
    { value: "4", label: "A Few Times A Week" },
    { value: "2", label: "Once A Week" },
    { value: "1", label: "Once a month" },
    { value: "0", label: "Seldom" }
];

const frequencyOptionsNegative = [
    { value: "0", label: "Everyday" },
    { value: "2", label: "A Few Times A Week" },
    { value: "3", label: "Once A Week" },
    { value: "4", label: "Once a month" },
    { value: "5", label: "Seldom" }
];

export {frequencyOptions, frequencyOptionsNegative};