const labels = {
    1: 'Very Poor',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
};

const labelsSatisfied = {
    1: 'Very Unsatisfied',
    2: 'Unsatisfied',
    3: 'Neutral',
    4: 'Satisfied',
    5: 'Very Satisfied',
};

const labelsComfort = {
    1: 'Very Uncomfortable',
    2: 'Somewhat Uncomfortable',
    3: 'Neutral',
    4: 'Comfortable',
    5: 'Very Comfortable',
}

export { labels, labelsSatisfied, labelsComfort };