import React, { useState, useRef } from 'react';
import formConfig from '../constants/EvaluationQuestions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Divider, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { handleSubmit as calculateScores } from '../logic/FormLogic';
import Question from './Question';
import LeftSideOrnaments from '../assets/images/leftSideOrnaments.png';
import RightSideOrnaments from '../assets/images/rightSideOrnaments.png';
import QOrnaments from '../assets/images/qOrnaments.png';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import dayjs from 'dayjs';

export default function EvaluationForm() {
  const [formValues, setFormValues] = useState({
    "screentime-beneficial-content": 50,
    "hobbies-time-available": dayjs("2022-04-17T00:00"),
    "hobbies-time-dedicated": dayjs("2022-04-17T00:00"),
  });
  const [emailError, setEmailError] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (id, value) => {
    setFormValues({ ...formValues, [id]: value });
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    handleChange("email", email);

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(email));
  };

  const handleStartEvalClick = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const { sectionRatings, overallLifeScore } = calculateScores(event, formValues);
    navigate('/life-report', { state: { sectionRatings, overallLifeScore } });
  };

  const renderQuestions = (questions) => {
    return questions.map((question) => (
      <Box sx={{position: "relative"}} key={question.id}>
        <img src={QOrnaments} alt="Right side of the page fine-line decoration for questions" style={{ position: 'absolute', top: '-15px', right: '-8px' }} />
        <Question question={question} formValues={formValues} handleChange={handleChange} />
      </Box>
    ));
  };

  const renderSections = () => {
    return formConfig.map((section) => (
      <Grid item xs={12} key={section.name}>
        <Typography variant="h4" sx={{ mb: 1, fontFamily: "Tuffy" }}>{section.name}</Typography>
        <Divider sx={{ width: '70%', borderColor: "black", mb: 5 }} fullWidth/>
        {renderQuestions(section.questions)}
      </Grid>
    ));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', maxWidth: "750px", mt: 5, mx: "auto" }}>
      <Box sx={{ flex: '1', mt: 5, pl: 3, pr: 1, position: "relative" }}>
        <img src={RightSideOrnaments} alt="Right side of the page fine-line decoration " style={{ position: 'absolute', top: '-80px', right: '0px' }} />
        <Typography sx={{ marginBottom: 2, fontFamily: "Tuffy", marginLeft: 2, position: "relative" }} variant="h4">Rate <strong><em>My Life</em></strong>
          <img src={LeftSideOrnaments} alt="Left side of the page fine-line decoration " style={{ position: 'absolute', bottom: '-125px', left: '-40px' }} />
        </Typography>
        <Box sx={{ px: 2, mt: 4, mb: 5 }}>
          <Typography variant="p">Whether you are looking for a <strong>place to reflect</strong> or need to evaluate <strong>what you really need to work on</strong> in your life, <u>this is the place</u>.</Typography>
        </Box>
        <Box sx={{ px: 1, my: 1 }}>
          <Alert sx={{ marginBottom: 5, fontFamily: "Tuffy" }} severity='info'>Questions are sorted into sections, and graded on <strong>your subjective evaluation</strong> of each section; <strong><u>not</u> by the criteria of anyone else</strong>.</Alert>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 'calc(100vh - 450px)', flexDirection: "column", mb: 5 }}>
          <Button sx={{ border: "2px solid", textTransform: "capitalize", mb: 4 }} onClick={handleStartEvalClick}>
            <Typography sx={{ fontFamily: "Tuffy", px: 4, py: 1 }} variant='h4'>Start Eval</Typography>
          </Button>
          <Typography sx={{ fontFamily: "Tuffy", marginLeft: 2, textAlign: "center", px: 5, mt: 4 }} variant='caption'><strong>Disclaimer:</strong><br /> The evaluation provided on this site is intended for informational purposes only. <br />Users should exercise caution and discretion when interpreting the results of the evaluation.</Typography>
        </Box>
        <Divider variant="middle" sx={{mb: 5}}></Divider>
      </Box>

      <Box sx={{ flex: '1', px: 2 }}>
        <form ref={formRef} id="evaluation-form" onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {renderSections()}
            {formValues["screentime-distraction-frequency"] && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="h6" sx={{ mb: 1, fontFamily: "Tuffy" }}>Subscribe for mobile app launch!</Typography>
                <TextField
                  type="email"
                  fullWidth
                  placeholder='email@email.com'
                  value={formValues["email"] || ''}
                  onChange={handleEmailChange}
                  required
                  error={emailError}
                  helperText={emailError ? 'Please enter a valid email address' : ''}
                />
                <Typography variant="p" sx={{ opacity: .75 }}>Results will be displayed once form is completed.</Typography>
                <Alert sx={{ mt: 2, fontFamily: "Tuffy" }} severity='info'>Results will be displayed once form is completed.</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth type="submit" sx={{mb:3}}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Footer />
    </Box>
  );
}
